<template>
	<div>
		<!-- Filters -->
		<data-filters
			:city-filter.sync="cityFilter"
			:twon-filter.sync="twonFilter"
			:sector-filter.sync="sectorFilter"
			:donar-filter.sync="donarFilter"
		/>

		<b-card>
			<b-row>
				<b-col cols="4">
					<bar-chart :height="150" :chart-data="chartData.barChart" />
				</b-col>
				<b-col md="4">
					<doughnut-chart :height="150" :chart-data="chartData.doughnutChart" />
				</b-col>
				<b-col md="4">
					<horizontal-bar-chart :height="150" :chart-data="chartData.horizontalBarChart" />
				</b-col>
			</b-row>
		</b-card>

		<!-- Table Container Card -->
		<b-form-checkbox v-model="showCharts" class="mb-1" switch inline>
			Show charts
		</b-form-checkbox>
		<b-card v-if="!showCharts" no-body class="mb-3">
			<b-table
				ref="refDataTable"
				class="position-relative"
				:items="fetchData"
				responsive
				:fields="tableColumns"
				:sort-by.sync="sortBy"
				show-empty
				bordered
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			/>
		</b-card>
		<b-card v-else>
			<b-row>
				<b-col cols="12">
					<line-chart :chart-data="chartData.lineChart" />
				</b-col>
				<b-col md="6">
					<radar-chart :chart-data="chartData.radarChart" />
				</b-col>
				<b-col md="6">
					<polar-area-chart :chart-data="chartData.polarChart" />
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>
import { BCard, BRow, BCol, BTable, BFormCheckbox } from 'bootstrap-vue';

import LineChart from '@/components/charts/LineChart';
import RadarChart from '@/components/charts/RadarChart';
import PolarAreaChart from '@/components/charts/PolarAreaChart';
import BarChart from '@/components/charts/BarChart';
import DoughnutChart from '@/components/charts/DoughnutChart.vue';
import HorizontalBarChart from '@/components/charts/HorizontalBarChart.vue';

import DataFilters from './DataFilters.vue';
import chartData from '../chartjsData';

export default {
	components: {
		DataFilters,
		BCard,
		BRow,
		BCol,
		BTable,
		BFormCheckbox,

		LineChart,
		RadarChart,
		PolarAreaChart,
		BarChart,
		DoughnutChart,
		HorizontalBarChart,
	},

	data: () => ({
		showCharts: false,
		tableColumns: [
			{ key: 'indicator', sortable: true },
			{ key: 'sub-projects', label: 'Number of Sub-Projects completed', sortable: true },
			{ key: 'inds', sortable: true },
		],

		cityFilter: null,
		twonFilter: null,
		sectorFilter: null,
		donarFilter: null,
		sortBy: 'sub-projects',
		isSortDirDesc: true,

		chartData,
	}),

	computed: {
		dataMeta() {
			const localItemsCount = this.$refs.refDataTable ? this.$refs.refDataTable.localItems.length : 0;
			return {
				from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
				to: this.perPage * (this.currentPage - 1) + localItemsCount,
				of: this.total,
			};
		},
	},

	watch: {
		cityFilter() {
			this.$refs.refDataTable.refresh();
		},
	},

	methods: {
		fetchData() {
			const result = [
				{ indicator: 'H_H_Memmber', 'sub-projects': 250, inds: 45 },
				{ indicator: 'Hectar_AGri', 'sub-projects': 165, inds: 35 },
				{ indicator: 'Farmares_AGri', 'sub-projects': 356, inds: 125 },
				{ indicator: 'Lengths_AGri', 'sub-projects': 67, inds: 34 },
				{ indicator: 'Number_school_reh', 'sub-projects': 125, inds: 87 },
			];

			result.sort((a, b) => (a.indicator > b.indicator ? 1 : -1));

			return new Promise((resolve) => {
				setTimeout(() => resolve(result), 500);
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
.table-responsive {
	margin-bottom: 0;
}
</style>
