<template>
	<b-card class="mb-0" no-body>
		<div>
			<h4 class="text-center mb-2">{{ title }}</h4>
		</div>

		<base-line-chart :height="height" :data="chartData.data" :options="chartData.options" :plugins="plugins" />
	</b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import BaseLineChart from './base-charts/BaseLineChart';

export default {
	name: 'ChartjsLineChart',

	components: {
		BCard,
		BaseLineChart,
	},

	props: {
		title: {
			type: String,
			default: '',
		},

		chartData: {
			type: Object,
			required: true,
		},

		height: {
			type: [Number, String],
			default: 400,
		},
	},

	data: () => ({
		plugins: [
			{
				beforeInit(chart) {
					/* eslint-disable no-param-reassign */
					chart.legend.afterFit = () => {
						this.height += 20;
					};
				},
			},
		],
	}),
};
</script>
