<template>
	<b-card no-body>
		<b-card-header class="pb-50">
			<h5>Filters</h5>
		</b-card-header>
		<b-card-body>
			<b-row>
				<b-col cols="12" md="3" class="mb-md-0 mb-2">
					<label>City</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="cityFilter"
						:options="cities"
						class="w-100"
						:reduce="(val) => val.value"
						@input="(val) => $emit('update:cityFilter', val)"
					/>
				</b-col>
				<b-col cols="12" md="3" class="mb-md-0 mb-2">
					<label>Twon</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="twonFilter"
						:options="twons"
						class="w-100"
						:reduce="(val) => val.value"
						@input="(val) => $emit('update:twonFilter', val)"
					/>
				</b-col>
				<b-col cols="12" md="3" class="mb-md-0 mb-2">
					<label>Sector</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="sectorFilter"
						:options="sectors"
						class="w-100"
						:reduce="(val) => val.value"
						@input="(val) => $emit('update:sectorFilter', val)"
					/>
				</b-col>
				<b-col cols="12" md="3" class="mb-md-0 mb-2">
					<label>Doner</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="donarFilter"
						:options="donars"
						class="w-100"
						:reduce="(val) => val.value"
						@input="(val) => $emit('update:donarFilter', val)"
					/>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardBody,
		vSelect,
	},
	props: {
		cityFilter: {
			type: [String, null],
			default: null,
		},
		twonFilter: {
			type: [String, null],
			default: null,
		},
		sectorFilter: {
			type: [String, null],
			default: null,
		},
		donarFilter: {
			type: [String, null],
			default: null,
		},
	},

	data: () => ({
		cities: [
			{ label: 'Sanaa', value: '1' },
			{ label: 'Aden', value: '2' },
			{ label: 'Taiz', value: '3' },
			{ label: 'Ibb', value: '4' },
		],

		twons: [
			{ label: 'Twon 1', value: '1' },
			{ label: 'Twon 1', value: '2' },
			{ label: 'Twon 1', value: '3' },
			{ label: 'Twon 1', value: '4' },
		],

		sectors: [
			{ label: 'Sector 1', value: '1' },
			{ label: 'Sector 1', value: '2' },
			{ label: 'Sector 1', value: '3' },
			{ label: 'Sector 1', value: '4' },
		],

		donars: [
			{ label: 'Doner 1', value: '1' },
			{ label: 'Doner 1', value: '2' },
			{ label: 'Doner 1', value: '3' },
			{ label: 'Doner 1', value: '4' },
		],
	}),
};
</script>

<style lang="scss"></style>
