import { $themeColors } from '@themeConfig';

// colors
const chartColors = {
	primaryColorShade: '#836AF9',
	yellowColor: '#ffe800',
	successColorShade: '#28dac6',
	warningColorShade: '#ffe802',
	warningLightColor: '#FDAC34',
	infoColorShade: '#299AFF',
	greyColor: '#4F5D70',
	blueColor: '#2c9aff',
	blueLightColor: '#84D0FF',
	greyLightColor: '#EDF1F4',
	tooltipShadow: 'rgba(0, 0, 0, 0.25)',
	lineChartPrimary: '#666ee8',
	lineChartDanger: '#ff4961',
	labelColor: '#6e6b7b',
	grid_line_color: 'rgba(200, 200, 200, 0.2)',
};

export default {
	barChart(labels, datasets) {
		return {
			data: {
				labels: labels || ['7/6', '8/6', '9/6', '10/6', '11/6', '12/6', '13/6'],
				datasets,
			},
			options: {
				elements: {
					rectangle: {
						borderWidth: 1,
						borderSkipped: 'bottom',
					},
				},
				responsive: true,
				maintainAspectRatio: false,
				responsiveAnimationDuration: 500,
				legend: {
					display: false,
				},
				tooltips: {
					// Updated default tooltip UI
					shadowOffsetX: 1,
					shadowOffsetY: 1,
					shadowBlur: 8,
					shadowColor: chartColors.tooltipShadow,
					backgroundColor: $themeColors.light,
					titleFontColor: $themeColors.dark,
					bodyFontColor: $themeColors.dark,
				},
				scales: {
					xAxes: [
						{
							display: true,
							gridLines: {
								display: true,
								color: chartColors.grid_line_color,
								zeroLineColor: chartColors.grid_line_color,
							},
							scaleLabel: {
								display: false,
							},
							ticks: {
								fontColor: chartColors.labelColor,
							},
						},
					],
					yAxes: [
						{
							display: true,
							gridLines: {
								color: chartColors.grid_line_color,
								zeroLineColor: chartColors.grid_line_color,
							},
							ticks: {
								// stepSize: parseInt(Math.max(...data) / 10, 10),
								// min: 0,
								// max: Math.max(...data) + parseInt(Math.max(...data) / 10, 10),
								// fontColor: chartColors.labelColor,
							},
						},
					],
				},
			},
		};
	},

	horizontalBarChart: {
		options: {
			elements: {
				rectangle: {
					borderWidth: 2,
					borderSkipped: 'top',
				},
			},
			tooltips: {
				// Updated default tooltip UI
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				shadowBlur: 8,
				shadowColor: chartColors.tooltipShadow,
				backgroundColor: $themeColors.light,
				titleFontColor: $themeColors.dark,
				bodyFontColor: $themeColors.dark,
			},
			responsive: true,
			maintainAspectRatio: false,
			responsiveAnimationDuration: 500,
			legend: {
				display: false,
			},
			scales: {
				xAxes: [
					{
						display: true,
						gridLines: {
							zeroLineColor: chartColors.grid_line_color,
							borderColor: 'transparent',
							color: chartColors.grid_line_color,
							drawTicks: false,
						},
						scaleLabel: {
							display: true,
						},
						ticks: {
							min: 0,
							fontColor: chartColors.labelColor,
						},
					},
				],
				yAxes: [
					{
						display: true,
						gridLines: {
							display: false,
						},
						scaleLabel: {
							display: true,
						},
						ticks: {
							fontColor: chartColors.labelColor,
						},
					},
				],
			},
		},
		data: {
			labels: ['MON', 'TUE', 'WED ', 'THU', 'SAT', 'SUN'],
			datasets: [
				{
					data: [710, 350, 470, 580, 460, 120],
					backgroundColor: $themeColors.info,
					borderColor: 'transparent',
					barThickness: 15,
				},
			],
		},
	},

	lineChart: {
		options: {
			responsive: true,
			maintainAspectRatio: false,
			backgroundColor: false,
			hover: {
				mode: 'label',
			},
			tooltips: {
				// Updated default tooltip UI
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				shadowBlur: 8,
				shadowColor: chartColors.tooltipShadow,
				backgroundColor: $themeColors.white,
				titleFontColor: $themeColors.black,
				bodyFontColor: $themeColors.black,
			},
			layout: {
				padding: {
					top: -15,
					bottom: -25,
					left: -15,
				},
			},
			scales: {
				xAxes: [
					{
						display: true,
						scaleLabel: {
							display: true,
						},
						gridLines: {
							display: true,
							color: chartColors.grid_line_color,
							zeroLineColor: chartColors.grid_line_color,
						},
						ticks: {
							fontColor: chartColors.labelColor,
						},
					},
				],
				yAxes: [
					{
						display: true,
						scaleLabel: {
							display: true,
						},
						ticks: {
							stepSize: 10,
							min: 0,
							max: 50,
							fontColor: chartColors.labelColor,
						},
						gridLines: {
							display: true,
							color: chartColors.grid_line_color,
							zeroLineColor: chartColors.grid_line_color,
						},
					},
				],
			},
			legend: {
				position: 'top',
				align: 'start',
				labels: {
					usePointStyle: true,
					padding: 25,
					boxWidth: 9,
				},
			},
		},
		data: {
			labels: ['7/6', '8/6', '9/6', '10/6', '11/6', '12/6', '13/6'],
			datasets: [
				{
					data: [16, 12, 21, 24, 10, 5, 9],
					label: 'Male',
					borderColor: chartColors.lineChartPrimary,
					lineTension: 0.5,
					pointStyle: 'circle',
					backgroundColor: chartColors.lineChartPrimary,
					fill: false,
					pointRadius: 1,
					pointHoverRadius: 5,
					pointHoverBorderWidth: 5,
					pointBorderColor: 'transparent',
					pointHoverBorderColor: $themeColors.white,
					pointHoverBackgroundColor: chartColors.lineChartPrimary,
					pointShadowOffsetX: 1,
					pointShadowOffsetY: 1,
					pointShadowBlur: 5,
					pointShadowColor: chartColors.tooltipShadow,
				},
				{
					data: [4, 8, 6, 12, 15, 10, 6],
					label: 'Female',
					borderColor: chartColors.warningColorShade,
					lineTension: 0.5,
					pointStyle: 'circle',
					backgroundColor: chartColors.warningColorShade,
					fill: false,
					pointRadius: 1,
					pointHoverRadius: 5,
					pointHoverBorderWidth: 5,
					pointBorderColor: 'transparent',
					pointHoverBorderColor: $themeColors.white,
					pointHoverBackgroundColor: chartColors.warningColorShade,
					pointShadowOffsetX: 1,
					pointShadowOffsetY: 1,
					pointShadowBlur: 5,
					pointShadowColor: chartColors.tooltipShadow,
				},
			],
		},
	},

	radarChart: {
		data: {
			labels: ['STA', 'STR', 'AGI', 'VIT', 'CHA', 'INT'],
			datasets: [
				{
					label: 'Project size',
					data: [25, 59, 90, 81, 60, 82],
					fill: true,
					backgroundColor: 'rgba(255,161,161, 0.9)',
					borderColor: 'transparent',
					pointBackgroundColor: 'transparent',
					pointBorderColor: 'transparent',
				},
				{
					label: 'Donation',
					data: [40, 100, 40, 90, 40, 90],
					fill: true,
					backgroundColor: 'rgba(155,136,250, 0.9)',
					borderColor: 'transparent',
					pointBackgroundColor: 'transparent',
					pointBorderColor: 'transparent',
				},
			],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			responsiveAnimationDuration: 500,
			legend: {
				position: 'top',
				labels: {
					padding: 25,
					fontColor: chartColors.labelColor,
				},
			},
			gridLines: {
				display: false,
			},
			scale: {
				ticks: {
					maxTicksLimit: 1,
					display: false,
					fontColor: chartColors.labelColor,
				},
				gridLines: {
					color: chartColors.grid_line_color,
				},
				angleLines: { color: chartColors.grid_line_color },
			},
		},
	},

	polarChart: {
		options: {
			responsive: true,
			maintainAspectRatio: false,
			responsiveAnimationDuration: 500,
			legend: {
				position: 'right',
				labels: {
					usePointStyle: true,
					padding: 25,
					boxWidth: 10,
					fontColor: chartColors.labelColor,
				},
			},
			tooltips: {
				// Updated default tooltip UI
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				shadowBlur: 8,
				shadowColor: chartColors.tooltipShadow,
				backgroundColor: $themeColors.light,
				titleFontColor: $themeColors.dark,
				bodyFontColor: $themeColors.dark,
			},
			scale: {
				scaleShowLine: true,
				scaleLineWidth: 1,
				ticks: {
					display: false,
					fontColor: chartColors.labelColor,
				},
				reverse: false,
				gridLines: {
					display: false,
				},
			},
			animation: {
				animateRotate: false,
			},
		},
		data: {
			labels: ['Plumber', 'Carpenter', 'Smith', 'Other'],
			datasets: [
				{
					label: 'Population (millions)',
					backgroundColor: [
						chartColors.primaryColorShade,
						chartColors.warningColorShade,
						$themeColors.primary,
						chartColors.infoColorShade,
						chartColors.greyColor,
						chartColors.successColorShade,
					],
					data: [19, 17.5, 15, 13.5],
					borderWidth: 0,
				},
			],
		},
	},

	doughnutChart(labels, data) {
		return {
			options: {
				responsive: true,
				maintainAspectRatio: false,
				responsiveAnimationDuration: 500,
				legend: { display: false },
				tooltips: {
					callbacks: {
						label(tooltipItem, data) {
							const total = data.datasets[0].data.reduce((prev, cur) => prev + cur, 0);
							const label = data.datasets[0].labels[tooltipItem.index] || '';
							const value = data.datasets[0].data[tooltipItem.index];
							const output = ` ${label} : ${value} (${((value / total) * 100).toFixed(1)} %)`;
							return output;
						},
					},
					// Updated default tooltip UI
					shadowOffsetX: 1,
					shadowOffsetY: 1,
					shadowBlur: 8,
					shadowColor: chartColors.tooltipShadow,
					backgroundColor: $themeColors.light,
					titleFontColor: $themeColors.dark,
					bodyFontColor: $themeColors.dark,
				},
			},
			data: {
				datasets: [
					{
						labels: labels || ['Tablet', 'Mobile', 'Desktop'],
						data,
						backgroundColor: [
							'rgba(255, 205, 86, 0.2)',
							'rgba(75, 192, 192, 0.2)',
							'rgba(54, 162, 235, 0.2)',
							'rgba(153, 102, 255, 0.2)',
						],
						borderColor: ['rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
						borderWidth: 1,
						pointStyle: 'rectRounded',
					},
				],
			},
		};
	},
};
